
































































































































import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { SvgImage, Pagination, Scrollbar, TwSelect } from '@/app/components/';
import store from '@/app/store';
import { FakeWorkflowOverview, WorkflowOverview } from '../components';
import { WorkflowAPI } from '../api';
import { Workflow } from '../types';
import { useWorkflows } from '../composable/workflow';
import { WorkflowSortingOption } from '@/modules/workflow-designer/constants';

export default defineComponent({
    name: 'Workflows',
    components: { WorkflowOverview, FakeWorkflowOverview, OrbitSpinner, SvgImage, Pagination, Scrollbar, TwSelect },
    setup(props, { root }) {
        const sortingOptions = WorkflowSortingOption.all();

        const searchText = ref<string>('');
        const sortBy = ref<string>('Date updated newest first');
        const searchRef = ref<any>(null);
        const pageSize = 10;

        const sortByOption = computed(
            (): WorkflowSortingOption => WorkflowSortingOption.findByLabel(sortBy.value) as WorkflowSortingOption,
        );

        const {
            workflows,
            filteredWorkflows,
            visibleWorkflows,
            errors,
            loading,
            page,
            setPage,
            refetch,
            exec,
        } = useWorkflows(pageSize, searchText, sortByOption);

        const currentPage = computed({
            get: () => page.value,
            set: (newPage: number) => {
                setPage(newPage);
            },
        });

        const openWorkflow = (workflow: Workflow) => {
            root.$router.push({ name: 'workflow-designer:edit', params: { id: workflow.id } });
        };

        const configureWorkflow = (workflow: Workflow) => {
            root.$router.push({ name: 'workflow-designer:edit', params: { id: workflow.id } });
        };

        const deleteWorkflow = (workflow: Workflow) => {
            exec(WorkflowAPI.delete(workflow.id))
                .then(async () => {
                    (root as any).$toastr.s(`Workflow '${workflow.name}' has been deleted`, 'Success');
                    await refetch();
                    if (visibleWorkflows.value.length === 0) {
                        setPage(1);
                    }
                })
                .catch(() => {
                    (root as any).$toastr.s(`Workflow '${workflow.name}' failed to be deleted`, 'Error');
                })
                .finally(() => {
                    store.commit.notificationEngine.SET_NOTIFICATIONS_AFTER_REFERENCED_ITEM_DELETE({
                        referenceId: workflow.id,
                        referenceType: 'Workflow',
                    });
                });
        };

        const historyWorkflow = (workflow: Workflow) => {
            root.$router.push({ name: 'history', params: { workflowId: workflow.id } });
        };

        // const historyEntry = (execution: Execution) => {
        //     console.log(execution);
        // };

        refetch();

        onMounted(() => {
            searchRef.value.focus();
        });

        return {
            loading,
            errors,
            searchRef,
            currentPage,
            pageSize,
            workflows,
            filteredWorkflows,
            visibleWorkflows,
            sortingOptions,
            sortBy,
            searchText,
            openWorkflow,
            configureWorkflow,
            deleteWorkflow,
            historyWorkflow,
            // historyEntry,
        };
    },
});
