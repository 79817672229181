
































































































































import * as R from 'ramda';
import { defineComponent, ref, onMounted, computed } from '@vue/composition-api';
import { LoopingRhombusesSpinner } from 'epic-spinners';
import { CogIcon, DownloadIcon, ChevronLeftIcon } from '@vue-hero-icons/outline';
import { useQuery } from '@/app/composable';
import { TwButton, SvgImage } from '@/app/components';
import { logoBase64 } from '../components/results-view/assets/logo/logoBase64';
import { ApexChart } from '../components/results-view/charts';
import { WarningMessages } from '../constants';
import { useVisualisation } from '../composable';
import GET_VISUALISATION from '../graphql/visualisation.graphql';
import { Chart } from '../types';
import { WorkflowStatus } from '@/modules/apollo/constants';

const OFFSET = {
    height: 250,
    width: 150,
};

export default defineComponent({
    name: 'WorkflowVisualisation',
    components: {
        LoopingRhombusesSpinner,
        TwButton,
        ApexChart,
        SvgImage,
        CogIcon,
        DownloadIcon,
        ChevronLeftIcon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    setup(props, { root }) {
        const chartRef = ref<any>({});
        const visualisationConfig = ref<any>(null);
        const retrievalData = ref<any>(null);
        const contentRef = ref<any>(null);
        const currentWorkflow = ref<any>(null);
        const hasNormalRunData = ref<boolean>(true);

        const {
            updateVisualisation,
            getVisualisationData,
            loading,
            error,
            computeVisualisationData,
            downloadChart,
        } = useVisualisation();

        const back = () => {
            root.$router.go(-1);
        };

        const goToConfiguration = () => {
            root.$router.push({ name: 'workflow-designer:edit', params: { id: currentWorkflow.value.id } });
        };

        const apolloRunner = useQuery(
            GET_VISUALISATION,
            { id: props.id },
            { fetchPolicy: 'no-cache', errorPolicy: 'all' },
        );
        apolloRunner.onResult((result: any) => {
            if (R.isNil(result?.visualisation)) return;
            const { visualisation } = result;

            currentWorkflow.value = visualisation.workflow;
            const completedExecutionIndex = visualisation?.workflow.executions.findIndex(
                (execution: any) => execution.status === 'completed',
            );

            visualisationConfig.value = {
                id: visualisation.id,
                title: visualisation.title,
                subtitle: visualisation.subtitle,
                type: visualisation.type,
                workflowId: visualisation.workflow.id,
                taskId: visualisation.task?.id || null,
                configuration: visualisation.configuration,
                assetId: visualisation.assetId,
                retrieval: visualisation.retrieval,
            };

            // Get retrieval data if there is assetId and normal run completed
            if (visualisation?.assetId && completedExecutionIndex !== -1) {
                const payload = { ...visualisation.retrieval, assetId: visualisation.assetId };
                getVisualisationData(payload).then((data: any) => {
                    retrievalData.value = data.data;
                });
            } else if (visualisation && completedExecutionIndex !== -1) {
                // Check for assetId and update visualisation retrieval configuration
                const assetId = visualisation.task?.configuration
                    ? visualisation.task.configuration.assetId.value
                    : null;
                if (assetId) {
                    const updatedRetrieval = {
                        ...visualisationConfig.value.retrieval,
                        assetId,
                    };

                    updateVisualisation({
                        ...visualisationConfig.value,
                        assetId,
                        retrieval: updatedRetrieval,
                    }).then((updatedVisualisationConfig: any) => {
                        visualisationConfig.value = updatedVisualisationConfig.data;
                        getVisualisationData(updatedRetrieval).then((data: any) => {
                            retrievalData.value = data.data;
                        });
                    });
                }
            } else if (completedExecutionIndex === -1) {
                hasNormalRunData.value = false;
            }
        });

        const busy = computed(() => loading.value || apolloRunner.loading.value);
        const errors = computed(() => {
            const errorsList = [];
            if (apolloRunner.error.value) {
                errorsList.push(apolloRunner.error.value.message);
            }
            if (error.value) {
                errorsList.push(error.value.message);
            }

            return errorsList;
        });

        const height = ref<any>(300);
        const width = ref<any>(300);

        const isFinilised = computed(() => {
            return currentWorkflow.value?.status === WorkflowStatus.Ready;
        });

        const hasValidConfiguration = computed(() => {
            if (!visualisationConfig.value?.assetId) return false;
            if (!retrievalData.value) return false;

            return true;
        });

        const handleResize = () => {
            const newHeight = contentRef.value?.clientHeight - OFFSET.height;
            const newWidth = contentRef.value?.clientWidth - OFFSET.width;
            height.value = newHeight;
            width.value = newWidth;
        };

        const newChart = computed(() =>
            visualisationConfig?.value?.configuration
                ? Chart?.findChart(visualisationConfig.value.configuration.type)
                : null,
        );

        const inputParams = computed(() => ({
            configuration: visualisationConfig?.value || null,
            width: width.value,
            height: height.value,
            visualisationData: retrievalData?.value || null,
            isPie: newChart.value?.iBelongInThePieFamily() || false,
        }));

        const chartData = computed(() => computeVisualisationData(inputParams.value));

        onMounted(() => {
            handleResize();
        });

        window.addEventListener('resize', handleResize);
        root.$once('hook:beforeDestroy', () => {
            window.removeEventListener('resize', handleResize);
        });
        return {
            downloadChart,
            logoBase64,
            back,
            busy,
            errors,
            chartRef,
            chartData,
            visualisationConfig,
            contentRef,
            goToConfiguration,
            hasValidConfiguration,
            isFinilised,
            hasNormalRunData,
            WarningMessages,
        };
    },
});
