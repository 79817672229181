import { AssetsAPI } from '@/modules/asset/api';
import { AssetTypeId } from '@/modules/asset/constants';
import { DataType as ConceptType } from '@/modules/data-model/constants/data-type.constants';
import { useAxios } from '@vue-composable/axios';
import { computed, Ref, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { DataType } from '../../constants';

const dataTypesMap = {
    [ConceptType.Boolean]: DataType.Boolean,
    [ConceptType.Time]: DataType.DateTime, // TODO: add time parameter component
    [ConceptType.Date]: DataType.DateTime, // TODO: add date parameter component
    [ConceptType.DateTime]: DataType.DateTime,
    [ConceptType.Double]: DataType.Double,
    [ConceptType.Integer]: DataType.Integer,
    [ConceptType.String]: DataType.String,
    [ConceptType.Binary]: DataType.String, // for binary links we store the minio urll, so they should be rendered as strings
};

export function useAssetStructureParameter(assetId: Ref<number | null>) {
    const { exec } = useAxios(true);
    const selectedAsset = ref<any>();

    const selectAsset = (id: number | null) => {
        if (id) {
            if (id === selectedAsset.value?.id) return;
            exec(AssetsAPI.getAsset(id)).then((response: any) => {
                selectedAsset.value = response.data;
            });
        } else {
            selectedAsset.value = null;
        }
    };

    const columns = computed(() => {
        if (selectedAsset.value) {
            const structure = datasetStructure.value || resultStructure.value;
            return (
                structure?.map((s) => ({
                    value: s.field,
                    label: s.field,
                    selectable: true,
                    type: s.type,
                })) || []
            );
        }
        return [];
    });

    const columnsWithTypes = computed(() => {
        if (columns.value) {
            return columns.value.reduce((prev, curr) => {
                return R.assocPath([curr.label], curr.type, prev);
            }, {});
        }
        return {};
    });

    const columnTypes = ref<string[]>([]);

    const flattenObject = (schema: Record<string, any>, fields: any[], pathSoFar: string, sep = '.') => {
        if (Object.keys(schema).includes('_index')) {
            if (!Object.keys(dataTypesMap).includes(schema['_type'])) return;

            fields.push({
                field: pathSoFar.startsWith(sep) ? pathSoFar.slice(1) : pathSoFar,
                type: dataTypesMap[schema['_type']],
            });
            return;
        }
        Object.entries(schema).forEach(([conceptName, concept]) => {
            if (!['_type', '_index', '_uid'].includes(conceptName)) {
                flattenObject(concept, fields, pathSoFar + sep + conceptName, sep);
            }
        });
    };

    const datasetStructure = computed(() => {
        if (selectedAsset.value && selectedAsset.value.assetTypeId === AssetTypeId.Dataset) {
            const mappingRules: {
                target: { path: string[]; title: string; type: string };
                transformation: { order?: number; multiple?: boolean };
            }[] = selectedAsset.value.processingRules.mappingRules;
            const fieldsToOrder = {};
            return mappingRules.map((rule) => {
                let field = [...rule.target.path, rule.target.title]
                    .join(isOptimised.value ? '_' : '.')
                    .replaceAll('[]', isOptimised.value ? '_0' : '');
                if (rule.transformation.order && isOptimised.value) {
                    field += `_${rule.transformation.order}`;
                } else if (rule.transformation.multiple && isOptimised.value) {
                    fieldsToOrder[field] = fieldsToOrder[field] ? fieldsToOrder[field] + 1 : 1;
                    field += `_${fieldsToOrder[field]}`;
                }
                return {
                    field,
                    type: rule.target.type,
                };
            });
        }
        return null;
    });

    const resultStructure = computed(() => {
        if (selectedAsset.value && selectedAsset.value.assetTypeId === AssetTypeId.Result) {
            const schema = R.omit(['customizedConcepts'], selectedAsset.value.structure?.schema);
            const col: { field: string; type: string }[] = [];
            flattenObject(schema, col, '', isOptimised.value ? '_' : '.');
            return col.map((c) => ({
                field: c.field,
                type: c.type,
            }));
        }
        return null;
    });

    const isOptimised = computed(() => selectedAsset?.value?.parquetStatus === 'optimised');

    const assetStructureForTask = computed(() => {
        if (!selectedAsset.value) return null;
        return {
            columns: columns.value,
            columnsWithTypes: columnsWithTypes.value,
            columnTypes: columnTypes.value,
            parquetStatus: selectedAsset.value.parquetStatus,
        };
    });

    watch(
        () => assetId.value,
        (id: number | null) => {
            selectAsset(id);
        },
        { immediate: true },
    );

    return { assetStructureForTask };
}
