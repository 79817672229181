



























































































































































































































































import { defineComponent, ref, watch, computed, Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { ValidationObserver } from 'vee-validate';

import { ConfirmButton, Scrollbar, OnClickOutside, ConfirmModal } from '@/app/components';

import { Task, Pipeline } from '../../types';
import TaskConfigurationHeader from './TaskConfigurationHeader.vue';
import TaskDataframesConfiguration from './TaskDataframesConfiguration.vue';
import TaskParameterConfiguration from './TaskParameterConfiguration.vue';
import TaskPipelinesConfiguration from './TaskPipelinesConfiguration.vue';
import { useTaskConfiguration } from '../../composable/task-configuration';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'TaskConfiguration',
    props: {
        task: {
            type: Object,
            required: true,
        },
        tasks: {
            type: Map,
            default: new Map<string, Task>(),
        },
        columnsPerTask: {
            type: Object,
        },
        runningExecution: {
            type: Object,
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        pipelines: {
            type: Array,
            default: () => [],
        },
        models: {
            type: Array,
            default: () => [],
        },
        isOnPremise: {
            type: Boolean,
            default: false,
        },
        runnerId: {
            type: String,
            default: null,
        },
        invalidTaskIds: {
            type: Array,
            default: () => [],
        },
        saving: {
            type: Boolean,
            default: false,
        },
        deleting: {
            type: Boolean,
            default: false,
        },
        isDeprecated: {
            type: Boolean,
            default: false,
        },
        isFinalised: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ConfirmButton,
        TaskConfigurationHeader,
        TaskDataframesConfiguration,
        TaskParameterConfiguration,
        ValidationObserver,
        Scrollbar,
        OnClickOutside,
        ConfirmModal,
        TaskPipelinesConfiguration,
    },
    setup(props, { emit }) {
        const taskConfig: Ref<Task> = ref<Task>(R.clone(props.task) as Task) as Ref<Task>;
        const tasks = ref<Map<string, Task>>(props.tasks as Map<string, Task>);
        const parametersRef = ref<any>();
        const showTaskEditCancelConfirmation = ref<boolean>(false);
        const forceUpdate = ref<Date>(new Date());
        const pipelinesClone = ref<Pipeline[]>(R.clone(props.pipelines) as Pipeline[]);
        const pipelineInEdit = ref<boolean>(false);
        const nameIsValid = ref<boolean>(true);

        const {
            getTask,
            blockCategory,
            blockType,
            availableDataframes,
            dataframeParameters,
            parameters,
            dataframeParametersConfig,
            parametersConfig,
            description,
            canBeInPipeline,
            allMachineLearningDataframes,
            availablePipeline,
            availableMachineLearningDataframes,
            assetStructureForTask,
        } = useTaskConfiguration(taskConfig, tasks, pipelinesClone);

        const showParameters = ref<boolean>(dataframeParameters.value.length === 0);

        const configuration = computed(() => {
            return {
                ...taskConfig.value,
                configuration: {
                    ...dataframeParametersConfig.value,
                    ...parametersConfig.value,
                },
            };
        });

        const isTaskRunning = computed(
            () =>
                !R.isNil(props.runningExecution) &&
                !R.isNil(props.runningExecution.task) &&
                props.runningExecution.task.id === props.task.id,
        );

        const isAnyTaskRunning = computed(() => !R.isNil(props.runningExecution));

        const waitingForDataframeCalculation = computed(() =>
            Object.keys(dataframeParametersConfig.value).reduce((acc: boolean, dataframeKey: string) => {
                const taskId = dataframeParametersConfig.value[dataframeKey].task;

                return (
                    acc ||
                    !props.columnsPerTask ||
                    !S.has(taskId, props.columnsPerTask) ||
                    R.isNil(props.columnsPerTask[taskId])
                );
            }, false),
        );

        const canSave = computed(() => {
            if (props.isFinalised) return false;
            for (let f = 0; f < parametersRef.value && Object.keys(parametersRef.value.fields).length; f++) {
                const fieldKey = Object.keys(parametersRef.value.fields)[f];
                const field = parametersRef.value.fields[fieldKey];
                if (Object.keys(field.failedRules).filter((rule: string) => rule !== 'required').length > 0) {
                    return false;
                }
            }
            return (
                (JSON.stringify(props.task) !== JSON.stringify(configuration.value) ||
                    JSON.stringify(props.pipelines) !== JSON.stringify(pipelinesClone.value)) &&
                !waitingForDataframeCalculation.value &&
                !pipelineInEdit.value &&
                nameIsValid.value
            );
            // if (checkDifferences) {
            //     emit('toggle-click-event', true);
            // } else {
            //     emit('toggle-click-event', false);
            // }
            // return checkDifferences;
        });

        const parametersDefinition = computed(() =>
            parameters.value.reduce((acc: any, p: any) => {
                acc[p.name] = p;
                return acc;
            }, {}),
        );

        const showSettings = (task: Task) => {
            emit('show-settings', task);
        };

        const deleteTask = () => {
            emit('delete', props.task);
        };

        const trimConfiguration = (config: any): any => {
            if (R.is(Object, config)) {
                return Object.keys(config).reduce((acc: any, key: string) => {
                    const c = config[key];
                    if (R.is(Array, c)) {
                        acc[key] = c.map((subConf: any) => trimConfiguration(subConf));
                    } else {
                        acc[key] = trimConfiguration(c);
                    }
                    return acc;
                }, {});
            }
            if (R.is(Array, config)) {
                return (config as any[]).map((c: any) => {
                    if (R.is(Array, c)) {
                        return c.map((subConf: any) => trimConfiguration(subConf));
                    }
                    return trimConfiguration(c);
                });
            }
            if (R.is(Boolean, config)) {
                return config;
            }
            return R.is(String, config) ? R.trim(config) : config;
        };

        const save = () => {
            emit('save', trimConfiguration(configuration.value), pipelinesClone.value);
        };

        const close = (confirm = true) => {
            if (canSave.value && confirm) {
                showTaskEditCancelConfirmation.value = true;
            } else {
                // emit('toggle-click-event', false);
                emit('close-task-configuration');
                showTaskEditCancelConfirmation.value = false;
            }
        };

        const setDataframeConfiguration = () => {
            showParameters.value = true;
        };

        const parameterChange = () => {
            if (parametersRef.value) {
                parametersRef.value.validate();
            }
            forceUpdate.value = new Date();
        };

        const testRun = () => {
            emit('test-run', props.task);
        };

        const isValid = (task: Task) => !props.invalidTaskIds.includes(task.id);

        const nameValidatyChange = (valid: boolean) => {
            nameIsValid.value = valid;
        };

        watch(
            () => props.task,
            (updatedTask: any) => {
                taskConfig.value = R.clone(updatedTask);
            },
        );

        watch(
            () => props.pipelines,
            (newPipelines: any) => {
                pipelinesClone.value = R.clone(newPipelines);
            },
        );

        watch(
            () => parametersRef.value,
            () => {
                parameterChange();
            },
        );

        return {
            save,
            close,
            taskConfig,
            getTask,
            showSettings,
            deleteTask,
            blockCategory,
            blockType,
            setDataframeConfiguration,
            dataframeParameters,
            availableDataframes,
            dataframeParametersConfig,
            parameters,
            parametersConfig,
            parametersRef,
            parameterChange,
            canSave,
            showParameters,
            configuration,
            description,
            showTaskEditCancelConfirmation,
            testRun,
            forceUpdate,
            isTaskRunning,
            isAnyTaskRunning,
            waitingForDataframeCalculation,
            canBeInPipeline,
            allMachineLearningDataframes,
            pipelinesClone,
            pipelineInEdit,
            availablePipeline,
            availableMachineLearningDataframes,
            parametersDefinition,
            isValid,
            nameValidatyChange,
            assetStructureForTask,
            R,
        };
    },
});
