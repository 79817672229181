import { useAxios } from '@vue-composable/axios';
import { ref, Ref } from '@vue/composition-api';
import { AssetsAPI } from '@/modules/asset/api';

export function useDatasetAssetParameter(runnerId: Ref<any>, root: any) {
    const { exec } = useAxios(true);

    const assets = ref<any>();

    const refetch = () => {
        exec(AssetsAPI.getAvailableDatasets())
            .then((response: any) => {
                if (runnerId.value !== null) {
                    assets.value = response.data
                        .filter(
                            (asset: any) =>
                                asset.metadata.runnerId === runnerId.value &&
                                asset.metadata?.filenames?.length > 0 &&
                                asset.metadata.distribution.accessibility.includes('Through an API'),
                        )
                        .map((asset: any) => {
                            return { ...asset, id: asset.id.toString() };
                        });
                } else {
                    // For assets created with cloud execution or on-premise execution with cloud storage, assets have no filenames
                    assets.value = response.data
                        .filter(
                            (asset: any) =>
                                (!asset.metadata.filenames || asset.metadata.filenames.length === 0) &&
                                asset.metadata.distribution.accessibility.includes('Through an API'),
                        )
                        .map((asset: any) => {
                            return { ...asset, id: asset.id.toString() };
                        });
                }
            })
            .catch(() => {
                (root as any).$toastr.e('Error while trying to retrieve datasets', 'Error');
            });
    };

    refetch();
    return { assets, refetch };
}
