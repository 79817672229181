import { FileHarvester } from '@/modules/apollo/components/harvesters';

export const IngestionMethods: { name: string; description: string; blockId: string; component?: any }[] = [
    {
        name: 'Upload Text Files',
        description: 'Direct file upload (CSV, JSON, XML)',
        blockId: 'dc.FileHarvester',
        component: FileHarvester,
    },
    {
        name: 'Upload Binary Files',
        description: 'Direct file upload of raw binary files (no processing)',
        blockId: 'dc.BinaryHarvester',
        component: FileHarvester,
    },
    {
        name: 'Streaming data to internal PubSub mechanism',
        description: 'Upload streaming data to the internal PubSub mechanism provided by the platform',
        blockId: 'dc.KafkaHarvester',
    },
    {
        name: 'Streaming data from own PubSub mechanism',
        description: 'Collect streaming data from the PubSub mechanism that is available on your end',
        blockId: 'dc.OwnKafkaHarvester',
    },
    {
        name: "Data provider's available API",
        description:
            'Collect data from the APIs provided by applications and systems of the data provider or from open APIs',
        blockId: 'dc.ApiHarvester',
    },
    {
        name: "Platform's API",
        description: "Upload data to the Platform's APIs",
        blockId: 'dc.InternalApiHarvester',
    },
];
