


























































































































import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import * as R from 'ramda';
import { S } from '@/app/utilities';
import { InputParameter } from '@/modules/workflow-designer/types';
import { ConfirmButton } from '@/app/components';

export default defineComponent({
    name: 'ComplexParameter',
    props: {
        value: {
            type: Object,
        },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        parameters: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
        },
        complexParameterIncomingProcessor: {
            type: Function,
            default: (parameterValue: any) => {
                return parameterValue.value;
            },
        },
        complexParameterOutgoingProcessor: {
            type: Function,
            default: (parameterValue: any) => {
                return { value: parameterValue };
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        models: {
            type: Array,
            default: () => [],
        },

        isOnPremise: {
            type: Boolean,
            default: false,
        },
        parametersDefinition: {
            type: Object,
            required: true,
        },
        assetStructureForTask: {
            type: Object,
            default: () => {},
        },
    },
    // Handling circular dependency
    components: {
        TaskParameterConfiguration: () => import('../TaskParameterConfiguration.vue'),
        ConfirmButton,
    },
    setup(props, { emit }) {
        const forceUpdate = ref<Date>(new Date());
        // Finds the component value based on incoming value and defaults
        const findInitialValue = (incomingValue: any) => {
            const processedValue = (props.complexParameterIncomingProcessor as any)(incomingValue);
            let resultingValue = props.parameter.multiple ? [] : {};
            if (!processedValue) {
                // Case no existing value defined
                if (S.has('default', props.parameter.validation)) {
                    // Case default value defined in validation information
                    resultingValue = props.parameter.validation.default;
                } else {
                    // Case no default value defined in validation information then take default empty value
                    resultingValue = props.parameter.multiple ? [] : {};
                }
            } else if (processedValue && props.parameter.multiple) {
                // Case where their is an existing value and the parameter is taking multiple values
                resultingValue = R.is(Array, processedValue) ? processedValue : [processedValue];
            } else if (processedValue && !props.parameter.multiple) {
                // Case where their is an existing value and the parameter is taking a single value
                resultingValue = processedValue;
            }
            return resultingValue;
        };

        const currentValue = ref<any>(findInitialValue(props.value));

        const isArray = computed(() => R.is(Array, currentValue.value));

        const hasValue = computed(() => !R.isNil(currentValue.value) && !R.isEmpty(currentValue.value));

        const subParameters = computed(() =>
            props.parameter.parameters.reduce((acc: any, parameter: any) => {
                acc[parameter.name] = parameter;
                return acc;
            }, {}),
        );

        const change = (parameter: InputParameter, settings: any, index: number | null = null) => {
            let updatedValue = {};
            if (!R.isNil(index)) {
                updatedValue = [...currentValue.value];
                updatedValue[index][parameter.name] = settings;
            } else {
                updatedValue = { ...currentValue.value };
                updatedValue[parameter.name] = settings;
            }
            currentValue.value = updatedValue;

            const processedUpdatedValue = (props.complexParameterOutgoingProcessor as any)(
                R.isEmpty(updatedValue) ? null : updatedValue,
            );
            emit('change', processedUpdatedValue);

            forceUpdate.value = new Date();
        };

        const addAnother = () => {
            currentValue.value.push({});
            const processedUpdatedValue = (props.complexParameterOutgoingProcessor as any)(
                R.isEmpty(currentValue.value) ? null : currentValue.value,
            );
            emit('change', processedUpdatedValue);
        };

        const deleteEntry = (index: number) => {
            currentValue.value.splice(index, 1);
            const processedUpdatedValue = (props.complexParameterOutgoingProcessor as any)(
                R.isEmpty(currentValue.value) ? null : currentValue.value,
            );
            emit('change', processedUpdatedValue);
        };

        const updateValue = (newValue: any) => {
            currentValue.value = findInitialValue(newValue);
            const processedUpdatedValue = (props.complexParameterOutgoingProcessor as any)(
                R.isEmpty(currentValue.value) ? null : currentValue.value,
            );
            if (JSON.stringify(newValue) !== JSON.stringify(processedUpdatedValue)) {
                emit('change', processedUpdatedValue);
            }
        };

        // Watch changes in the incoming value to adapt the currently held value
        watch(
            () => props.value,
            (newValue: any) => updateValue(newValue),
        );

        updateValue(props.value);

        return { currentValue, isArray, change, addAnother, deleteEntry, hasValue, forceUpdate, subParameters, R };
    },
});
