import { useAxios } from '@vue-composable/axios';
import { computed, onBeforeUnmount, Ref, ref } from '@vue/composition-api';
import * as R from 'ramda';
import { TopologicalSort } from 'topological-sort';
import { useQuery, useResult, useErrors, useSSE } from '@/app/composable';
import { SseQueue } from '@/app/constants';
import { ScheduleAPI, TaskAPI, WorkflowAPI } from '../api';
import {
    BlockCategoryWrapper,
    BlockOutputType,
    ExecutionFrameworkWrapper,
    ExecutionStatus,
    ExecutionStatusWrapper,
    ExecutionType,
    ExecutionTypeWrapper,
    MessageType,
} from '../constants';
import GET_TASKS from '../graphql/tasks.graphql';
import { EventMessage, Pipeline, RunningExecution, Task } from '../types';
import { useValidator } from './validator';
import { S } from '@/app/utilities';
import { WorkflowStatus } from '@/modules/apollo/constants';
import { Asset } from '@/modules/asset/types';
import { StatusCode } from '@/modules/asset/constants';

export function useWorkflowDesigner(workflowId: string, toastr: any) {
    const { checkGQLAuthentication } = useErrors();
    const apolloRunner = useQuery(GET_TASKS, { id: workflowId }, { fetchPolicy: 'no-cache' });
    const axiosRunner = useAxios(true);
    apolloRunner.onError(checkGQLAuthentication);

    const workflow = ref<any>(null);
    const pipelines = ref<Pipeline[]>([]);
    const tasks: Ref<any[] | null> = ref<Task[] | null>(null);
    const visualisations = ref<{ id: string; task: { id: string } }[] | null>(null);
    const taskMap = ref<Map<string, Task>>(new Map());
    const schedules = ref<any[]>([]);
    const runningExecution: Ref<any> = ref<RunningExecution | null>(null);
    const otherRunningExecutions = ref<RunningExecution[]>([]);
    const pendingExecutions: Ref<any[]> = ref<
        {
            type: ExecutionType;
            task?: Task | null;
        }[]
    >([]);
    const resultAssets: Ref<Asset[]> = ref<Asset[]>([]);
    const { invalidTaskIds, runValidation, validationErrors, workflowDags } = useValidator(workflow, taskMap);

    const executionFrameworkWrapper = computed(() =>
        workflow.value ? ExecutionFrameworkWrapper.find(workflow.value.framework) : null,
    );

    const isFinalised = computed(() => workflow.value?.status === WorkflowStatus.Ready);
    const isDeprecated = computed(() => workflow.value?.status === WorkflowStatus.Deprecated);
    const canBeReopened = computed(
        () =>
            isFinalised.value &&
            resultAssets.value.filter((asset: Asset) =>
                [StatusCode.Uploading, StatusCode.Incomplete, StatusCode.Internal].includes(asset.status),
            ).length === resultAssets.value.length,
    );
    const executionKeyCalculator = (type: ExecutionType, task?: Task) =>
        !R.isNil(task) ? `${type}_${task.id}` : `${type}_workflow`;

    const openExecutionsIds = computed(() => {
        const pendingExecutionIds = pendingExecutions.value.map(
            (pendingExecution: { type: ExecutionType; task?: Task | null }) =>
                executionKeyCalculator(
                    pendingExecution.type,
                    R.isNil(pendingExecution.task) ? undefined : pendingExecution.task,
                ),
        );
        return R.isNil(runningExecution.value)
            ? pendingExecutionIds
            : [
                  executionKeyCalculator(runningExecution.value.type, runningExecution.value.task),
                  ...pendingExecutionIds,
              ];
    });

    const executeRun = (request: any): Promise<{ executionId: any; taskId: any }> => {
        return new Promise((resolve, reject) => {
            request
                .then((response: any) => {
                    resolve({
                        executionId: response.data.id,
                        taskId: response.data.outputTaskId,
                    });
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    };

    const runExecution = async (type: ExecutionType, task?: Task) => {
        runningExecution.value = {
            task,
            type,
            status: ExecutionStatus.Queued,
        };
        try {
            const result: { executionId: any } = await executeRun(
                axiosRunner.exec(
                    WorkflowAPI.run(
                        workflowId,
                        type,
                        task ? task.id : null,
                        workflow.value.configuration.frameworkVersion ||
                            executionFrameworkWrapper.value?.version ||
                            'latest',
                        workflow.value.configuration.sample,
                    ),
                ),
            );

            if (runningExecution.value) runningExecution.value.executionId = result.executionId;
        } catch (error) {
            if (runningExecution.value) {
                const typeWrapper = ExecutionTypeWrapper.find(type);
                if (typeWrapper) {
                    runningExecution.value = null;
                    const { message, category } = typeWrapper.message(
                        ExecutionStatus.Failed,
                        task,
                        (error as any).response.data.message,
                    );
                    toastr.e(message, category);
                }
            }
        }
    };

    const queueExecution = async (type: ExecutionType, task?: Task) => {
        openExecutionsIds.value.filter((id: any) => id !== executionKeyCalculator(type, task));

        if (R.isNil(runningExecution.value)) {
            await runExecution(type, task);
        } else if (R.isNil(task)) {
            pendingExecutions.value.push({
                type,
            });
        } else {
            pendingExecutions.value.push({
                type,
                task,
            });
        }
    };

    // Find currently running task
    const setCurrentlyRunningExecution = () => {
        if (!R.isNil(tasks.value) && tasks.value.length > 0) {
            const queuedWorkflow = workflow.value.executions.reduce((acc: RunningExecution[], execution: any) => {
                if (execution.status === ExecutionStatus.Queued) {
                    acc.push({
                        executionId: execution.id,
                        type: execution.type,
                        status: execution.status,
                    });
                }
                return acc;
            }, []);

            const runningWorkflow = workflow.value.executions.reduce((acc: RunningExecution[], execution: any) => {
                if (execution.status === ExecutionStatus.Running) {
                    acc.push({
                        executionId: execution.id,
                        type: execution.type,
                        status: execution.status,
                    });
                }
                return acc;
            }, []);
            const queuedTasks = tasks.value.reduce((acc: RunningExecution[], task: Task) => {
                const queuedExecutions = task.executions.filter(
                    (execution: any) => execution.status === ExecutionStatus.Queued,
                );
                for (let qE = 0; qE < queuedExecutions.length; qE++) {
                    const queuedExecution = queuedExecutions[qE];
                    acc.push({
                        task,
                        executionId: queuedExecution.id,
                        type: queuedExecution.type,
                        status: queuedExecution.status,
                    });
                }
                return acc;
            }, []);
            const runningTasks = tasks.value.reduce((acc: RunningExecution[], task: Task) => {
                const queuedExecutions = task.executions.filter(
                    (execution: any) => execution.status === ExecutionStatus.Running,
                );
                for (let qE = 0; qE < queuedExecutions.length; qE++) {
                    const queuedExecution = queuedExecutions[qE];
                    acc.push({
                        task,
                        executionId: queuedExecution.id,
                        type: queuedExecution.type,
                        status: queuedExecution.status,
                    });
                }
                return acc;
            }, []);

            const inProgressExecutions = [...runningWorkflow, ...runningTasks, ...queuedWorkflow, ...queuedTasks];
            if (inProgressExecutions.length === 0) {
                runningExecution.value = null;
                otherRunningExecutions.value = [];
                return;
            }

            if (
                inProgressExecutions.length > 1 &&
                !R.isNil(runningExecution.value) &&
                !R.isNil(runningExecution.value.executionId) &&
                R.pluck('executionId')(inProgressExecutions).includes(runningExecution.value.executionId)
            ) {
                const taskIndex = R.findIndex(R.propEq('executionId', runningExecution.value.executionId))(
                    inProgressExecutions,
                );
                runningExecution.value = {
                    task: S.has('task', inProgressExecutions[taskIndex]) ? inProgressExecutions[taskIndex].task : null,
                    executionId: inProgressExecutions[taskIndex].executions[0].id,
                    type: inProgressExecutions[taskIndex].executions[0].type,
                    status: inProgressExecutions[taskIndex].executions[0].status,
                };
            } else if (inProgressExecutions.length > 0) {
                [runningExecution.value] = inProgressExecutions;
            }

            otherRunningExecutions.value = inProgressExecutions.filter(
                (execution: RunningExecution) =>
                    !R.isNil(runningExecution.value) && execution.executionId !== runningExecution.value.executionId,
            );
        }
    };

    apolloRunner.onResult(async () => {
        pipelines.value = [];
        const workflowRes = useResult(apolloRunner.result, null, (data: any) => data.workflow).value;
        if (!R.isNil(workflowRes)) {
            const unsortedTasks = useResult(apolloRunner.result, null, (data: any) => data.workflow.tasks)
                .value as Task[];
            workflow.value = R.pick(
                [
                    'id',
                    'name',
                    'description',
                    'type',
                    'framework',
                    'platform',
                    'runner',
                    'status',
                    'configuration',
                    'createdAt',
                    'updatedAt',
                    'executions',
                ],
                workflowRes,
            );
            visualisations.value = workflowRes.visualisations;
            pipelines.value = R.clone(workflow.value.configuration.pipelines);

            taskMap.value = new Map<string, Task>();
            if (R.isNil(unsortedTasks)) {
                tasks.value = [];
            } else {
                for (let t = 0; t < unsortedTasks.length; t++) {
                    const task: Task = unsortedTasks[t];
                    taskMap.value.set(task.id, task);
                }

                const topoSort = new TopologicalSort(taskMap.value);
                for (let t = 0; t < unsortedTasks.length; t++) {
                    const task: Task = unsortedTasks[t] as Task;
                    for (let e = 0; e < task.downstreamTaskIds.length; e++) {
                        const edge = task.downstreamTaskIds[e];
                        topoSort.addEdge(task.id, edge);
                    }
                }
                const sorted = topoSort.sort();
                const sortedKeys = [...sorted.keys()];
                const sortedTasks: Task[] = [];
                for (let s = 0; s < sortedKeys.length; s++) {
                    const taskId = sortedKeys[s];
                    if (taskMap.value.has(taskId)) {
                        sortedTasks.push(taskMap.value.get(taskId) as Task);
                    }
                }

                tasks.value = sortedTasks;
            }

            ScheduleAPI.getSchedules(workflow.value.id).then((resSchedules: any) => {
                schedules.value = resSchedules.data;
            });

            axiosRunner.exec(WorkflowAPI.getResults(workflow.value.id)).then((response: any) => {
                resultAssets.value = response.data;
            });

            setCurrentlyRunningExecution();
            await runValidation();

            // Calculate tasks that need to do a test run
            pendingExecutions.value = pendingExecutions.value.filter(
                (execution: { type: ExecutionType; task?: Task | null }) => execution.type !== ExecutionType.Dry,
            );
            workflowDags.value.forEach((dag: string[]) => {
                let hasFailure = false;
                dag.forEach(async (taskId: string) => {
                    if (!hasFailure && taskMap.value.has(taskId)) {
                        const task: Task = taskMap.value.get(taskId) as Task;
                        const category = BlockCategoryWrapper.find(task.block.category);
                        if (task.executions.length > 0 && task.executions[0].status === ExecutionStatus.Failed) {
                            hasFailure = true;
                        } else if (
                            category?.canDryRun &&
                            task.block.output.type === BlockOutputType.Dynamic &&
                            task.executions.length === 0 &&
                            !invalidTaskIds.value.includes(task.id)
                        ) {
                            await queueExecution(ExecutionType.Dry, task);
                        }
                    }
                });
            });
        }
    });

    const errors = computed(() => {
        const errorsList = [];
        if (apolloRunner.error.value) {
            errorsList.push(apolloRunner.error.value.message);
        }

        return errorsList;
    });

    const loading = computed(() => apolloRunner.loading.value);

    const taskVisualisations = computed(() =>
        visualisations.value
            ? visualisations.value.reduce((acc: any, visualisation: { id: string; task: { id: string } }) => {
                  acc[visualisation.task.id] = visualisation.id;
                  return acc;
              }, {})
            : {},
    );

    const { refetch, onError } = apolloRunner;

    const models = ref([]);

    axiosRunner.exec(WorkflowAPI.getModels()).then((response: any) => {
        models.value = response.data;
    });

    const createTask = (taskPayload: any) => axiosRunner.exec(WorkflowAPI.createTask(taskPayload));

    const updateTask = (task: Task) => axiosRunner.exec(TaskAPI.update(task));

    const deleteTask = (task: Task) => axiosRunner.exec(TaskAPI.delete(task.id));

    const finaliseWorkflow = async () => {
        return new Promise<void>((resolve, reject) => {
            axiosRunner
                .exec(WorkflowAPI.finalise(workflow.value.id))
                .then(() => {
                    if (axiosRunner.error.value) {
                        reject(axiosRunner.error.value);
                    } else {
                        resolve();
                    }
                    refetch();
                })
                .catch(reject);
        });
    };

    const saveSchedule = async (schedule: any) => {
        return new Promise<string>((resolve, reject) => {
            if (schedule.id) {
                ScheduleAPI.update(schedule.id, schedule)
                    // exec(ScheduleAPI.update(schedule.id, schedule))
                    .then(() => {
                        ScheduleAPI.getSchedules(workflow.value.id)
                            // exec(ScheduleAPI.getSchedules(workflow.value.id))
                            .then((resAll: any) => {
                                schedules.value = resAll.data;
                            });
                        resolve('Schedule has been updated successfully');
                    })
                    .catch((e) => {
                        reject(e.response.data.message);
                    });
            } else {
                ScheduleAPI.create(schedule)
                    // exec(ScheduleAPI.create(schedule))
                    .then(() => {
                        ScheduleAPI.getSchedules(workflow.value.id)
                            // exec(ScheduleAPI.getSchedules(workflow.value.id))
                            .then((resAll: any) => {
                                schedules.value = resAll.data;
                            });
                        resolve('Schedule has been created successfully');
                    })
                    .catch((e) => {
                        reject(e.response.data.message);
                    });
            }
        });
    };

    const changeScheduleStatus = (schedule: any) => {
        const idx = schedules.value.findIndex((obj: any) => obj.id === schedule.id);
        if (schedule.isEnabled) {
            ScheduleAPI.deactivate(schedule.id)
                // exec(ScheduleAPI.deactivate(schedule.id))
                .then(() => {
                    schedules.value[idx].isEnabled = false;
                });
        } else {
            ScheduleAPI.activate(schedule.id)
                // exec(ScheduleAPI.activate(schedule.id))
                .then(() => {
                    schedules.value[idx].isEnabled = true;
                });
        }
    };

    const deleteSchedule = (id: string) => {
        ScheduleAPI.delete(id)
            // exec(ScheduleAPI.delete(id))
            .then(() => {
                const idx = schedules.value.findIndex((obj: any) => obj.id === id);
                schedules.value.splice(idx, 1);
            });
    };

    const fetchModels = async () => {
        await axiosRunner.exec(WorkflowAPI.getModels()).then((response: any) => {
            models.value = response.data;
        });
    };

    const unlockWorkflow = async () => {
        await axiosRunner.exec(WorkflowAPI.unlock(workflowId)).then((response: any) => {
            refetch();
        });
    };

    // HANDLE SSE
    const { initialise: initSSE, destroy: destroySSE } = useSSE();
    const onMessage = async (data: EventMessage) => {
        // Handle the case where we have an execution status change
        if (
            data.type === MessageType.Status &&
            (R.isNil(data.body.taskId) ||
                (taskMap.value.has(data.body.taskId) && !R.isNil(taskMap.value.get(data.body.taskId)))) &&
            Object.values(ExecutionType).includes(data.body.executionType as ExecutionType)
        ) {
            const executedTask = data.body.taskId ? taskMap.value.get(data.body.taskId) : null;
            const executionType = data.body.executionType ? ExecutionTypeWrapper.find(data.body.executionType) : null;
            if (!R.isNil(executionType) && !R.isNil(data.body.status)) {
                const { message, category } = executionType.message(data.body.status, executedTask, data.body.message);
                if (data.body.status === ExecutionStatus.Cancelled) {
                    toastr.w(message, category);
                } else if (data.body.status === ExecutionStatus.Failed) {
                    toastr.e(message, category);
                }
            }

            // Figure out if the currently running execution has completed
            if (!R.isNil(runningExecution.value) && !R.isNil(data.body.status)) {
                if (
                    data.executionId === runningExecution.value.executionId &&
                    ExecutionStatusWrapper.finishedStatuses().includes(data.body.status)
                ) {
                    // If execution has completed (succesfully or otherwise) clear running execution
                    // and refetch task data and models
                    // TODO: Test if any conflicts
                    // runningExecution.value = null;
                    await refetch();
                    await fetchModels();
                } else {
                    runningExecution.value = { ...runningExecution.value, status: data.body.status };
                }
            }

            // In case there are no running executions but there are pending ones
            // then call the next pending one
            if (
                R.isNil(runningExecution.value) &&
                pendingExecutions.value.length > 0 &&
                !R.isNil(pendingExecutions.value[0].task)
            ) {
                const executionToBeRun = pendingExecutions.value.shift();
                if (executionToBeRun && executionToBeRun.task)
                    runExecution(executionToBeRun.type, executionToBeRun.task);
            }
        }
    };
    initSSE(`/api/workflow/${workflowId}/sse`, SseQueue.Workflow, onMessage);

    onBeforeUnmount(() => {
        destroySSE();
    });

    fetchModels();

    return {
        workflow,
        tasks,
        taskMap,
        schedules,
        errors,
        loading,
        runningExecution,
        pendingExecutions,
        otherRunningExecutions,
        validationErrors,
        invalidTaskIds,
        pipelines,
        models,
        visualisations,
        taskVisualisations,
        isFinalised,
        isDeprecated,
        canBeReopened,
        refetch,
        onError,
        createTask,
        updateTask,
        deleteTask,
        saveSchedule,
        runValidation,
        unlockWorkflow,
        deleteSchedule,
        queueExecution,
        finaliseWorkflow,
        changeScheduleStatus,
    };
}
