































































































































































































import * as R from 'ramda';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { useAxios } from '@vue-composable/axios';
import { maxLengthValidator, requiredValidator } from '@/app/validators';
import { ConfirmModal, TwSelect, OnClickOutside } from '@/app/components';
import { RunnerAPI } from '@/modules/data-checkin/api';
import { ExecutionFrameworkWrapper } from '../../constants';
import SampleConfiguration from './SampleConfiguration.vue';

extend('required', requiredValidator);
extend('max', maxLengthValidator);

export default defineComponent({
    name: 'Configure',
    props: {
        workflow: { type: Object, required: true },
        isFinalised: { type: Boolean, default: false },
        isDeprecated: { type: Boolean, default: false },
    },
    components: { ConfirmModal, ValidationObserver, ValidationProvider, TwSelect, OnClickOutside, SampleConfiguration },
    setup(props, { emit }) {
        const { exec } = useAxios();
        const justCreated = ref(true);
        const showWorkflowEditCancelConfirmation = ref(false);
        const frameworks = ExecutionFrameworkWrapper.all();
        const workflowToBeEdited = ref(R.clone(props.workflow));

        const frameworkImagePath = computed(() => {
            const framework = ExecutionFrameworkWrapper.find(props.workflow.framework);
            if (framework) {
                return framework.imagePath;
            }
            return null;
        });
        const workflowConfigurationRef = ref(null);

        const runners = ref([]);
        // Load registered runners
        exec(RunnerAPI.all()).then((response: any) => {
            runners.value = response.data;
        });

        const execLocations = ref(['Cloud', 'On-Premise']);
        const location = ref(props.workflow.runner && props.workflow.runner.id ? 'On-Premise' : 'Cloud');
        const selectedRunner = ref(props.workflow.runner ? props.workflow.runner.id : null);
        const sampleConfiguration = ref(
            props.workflow.configuration?.sample
                ? props.workflow.configuration.sample
                : {
                      size: {
                          rowsCount: 1000,
                          percentage: null,
                      },
                      selection: 'first',
                  },
        );

        const differencesExist = computed(() => {
            if (workflowToBeEdited.value) {
                const checkDifferences =
                    workflowToBeEdited.value.name !== props.workflow.name ||
                    workflowToBeEdited.value.description !== props.workflow.description ||
                    // (workflowToBeEdited.value.runner && workflowToBeEdited.value.runner.id !== selectedRunner.value);
                    (props.workflow.runner && props.workflow.runner.id !== selectedRunner.value) ||
                    JSON.stringify(props.workflow.configuration.sample) !== JSON.stringify(sampleConfiguration.value);

                if (checkDifferences) {
                    emit('toggle-click-event', true);
                }
                // else {
                //     emit('toggle-click-event', false);
                // }
                return checkDifferences;
            }
            return false;
        });

        const close = (confirm = true) => {
            if (differencesExist.value && confirm) {
                showWorkflowEditCancelConfirmation.value = true;
            } else if (!justCreated.value) {
                emit('toggle-click-event', false);
                emit('cancel');
                showWorkflowEditCancelConfirmation.value = false;
            }
            justCreated.value = false;
        };

        const save = () => {
            emit('save-configuration', {
                name: workflowToBeEdited.value.name,
                description: workflowToBeEdited.value.description,
                runnerId: selectedRunner.value,
                configuration: {
                    sample: sampleConfiguration.value,
                },
            });
        };

        return {
            showWorkflowEditCancelConfirmation,
            close,
            frameworks,
            workflowConfigurationRef,
            frameworkImagePath,
            runners,
            // runnerId,
            save,
            emit,
            workflowToBeEdited,
            location,
            execLocations,
            differencesExist,
            selectedRunner,
            sampleConfiguration,
        };
    },
});
