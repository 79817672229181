































































































































































































































































































































import { defineComponent, computed, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import utc from 'dayjs/plugin/utc';
import { VPopover } from 'v-tooltip';
import * as R from 'ramda';
import { ChartBarIcon, ExclamationCircleIcon } from '@vue-hero-icons/solid';
import { ConfirmButton } from '@/app/components';
import { ExecutionFrameworkWrapper, ExecutionStatus } from '../constants';
import { WorkflowStatus } from '@/modules/apollo/constants';

dayjs.extend(weekOfYear);
dayjs.extend(utc);

export default defineComponent({
    name: 'WorkflowOverview',
    props: {
        workflow: {
            type: Object,
            required: true,
        },
    },
    components: { ConfirmButton, VPopover, ChartBarIcon, ExclamationCircleIcon },
    setup(props: any, { root, emit }: { root: any; emit: any }) {
        const isExpanded = ref<boolean>(false);
        const { reverse } = R;

        const hasVisualisations = computed(() => props.workflow.visualisations.length > 0);

        const toggleExpand = () => {
            if (hasVisualisations.value) {
                isExpanded.value = !isExpanded.value;
            }
        };

        const createdAtShort = computed(() => {
            return dayjs(props.workflow.createdAt).utc().fromNow();
        });

        const createFullDatetime = (date: Date) => {
            return dayjs(date).utc().format('DD/MM/YYYY HH:mm');
        };

        const createdAtFull = computed(() => createFullDatetime(props.workflow.createdAt));
        const frameworkImagePath = computed(() => {
            const framework = ExecutionFrameworkWrapper.find(props.workflow.framework);
            if (framework) {
                return framework.imagePath;
            }
            return null;
        });

        const openWorkflow = () => {
            emit('open', props.workflow);
        };

        const configureWorkflow = () => {
            emit('configure', props.workflow);
        };

        const deleteWorkflow = () => {
            emit('delete', props.workflow);
        };

        const historyWorkflow = () => {
            emit('history', props.workflow);
        };

        const showExecutionDetails = (execution: any) => {
            root.$router.push({
                name: 'history',
                params: { id: execution.id, workflowId: props.workflow.id, status: execution.status },
            });
        };

        return {
            isExpanded,
            createdAtFull,
            createdAtShort,
            WorkflowStatus,
            ExecutionStatus,
            hasVisualisations,
            frameworkImagePath,
            reverse,
            openWorkflow,
            toggleExpand,
            deleteWorkflow,
            historyWorkflow,
            createFullDatetime,
            configureWorkflow,
            showExecutionDetails,
        };
    },
});
