import { computed } from '@vue/composition-api';
import { InputParameter } from '@/modules/workflow-designer/types';
import { S } from '@/app/utilities';
import { JobBlock } from '@/modules/apollo/interfaces';

export function useApolloBlock(block: JobBlock) {
    const buildParametersTree = (parameters: any[], prefix?: string) => {
        return parameters.reduce((acc: Record<string, any>, param: InputParameter) => {
            const parameterName = prefix ? `${prefix}.${param.name}` : param.name;
            acc[parameterName] = param;
            if (param.parameters && param.parameters.length > 0) {
                const children = buildParametersTree(param.parameters, parameterName);
                acc = { ...acc, ...children };
            }
            return acc;
        }, {});
    };

    const parameters = computed(() => buildParametersTree(block.parameters));

    const hasParameter = (parameterName: string) => {
        return S.has(parameterName, parameters.value);
    };

    const getParameter = (parameterName: string) => {
        if (!hasParameter(parameterName)) {
            throw Error(`Parameter with name ${parameterName} does not exist in block ${block.name}`);
        }
        return parameters.value[parameterName];
    };

    const getName = (parameterName: string) => {
        return getParameter(parameterName).name;
    };

    const getDescription = (parameterName: string, defaultDescription?: string) => {
        const parameter = getParameter(parameterName);
        return S.has('description', parameter) ? parameter.description : defaultDescription;
    };

    const getPossibleValues = (parameterName: string, defaultValues = []) => {
        const parameter = getParameter(parameterName);
        return S.has('validation', parameter) && S.has('values', parameter.validation)
            ? parameter.validation.values
            : defaultValues;
    };

    const getDefaultValue = (parameterName: string, defaultValue?: any) => {
        const parameter = getParameter(parameterName);
        return S.has('validation', parameter) && S.has('default', parameter.validation)
            ? parameter.validation.default
            : defaultValue;
    };

    const clearParameter = () => {};

    return { parameters, getName, hasParameter, getDescription, getPossibleValues, getDefaultValue, clearParameter };
}
